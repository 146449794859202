@import url('https://fonts.googleapis.com/css2?family=Michroma&family=Press+Start+2P&display=swap');
body{
  padding-bottom: env(safe-area-inset-bottom); /* Add this line */
}

.App {
  text-align: center;
  background-color: #dddddd;
  padding-bottom: env(safe-area-inset-bottom); /* Add this line */
  padding-top: env(safe-area-inset-top); /* Add this line */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.byline{
  font-size:10px;
  margin:-20px 0px;
}

.available{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.availabletag{
  font-size:11px;
  position:aboslute;
  padding:0px 0px;
  float:right;
  margin:-5px 0px;
  background:white;
  padding:5px 40px;
}

h1{
  font-size:22px;
  font-family: 'Press Start 2P', cursive;
  margin:0px;
  color:white;
}

h3{
  font-size:50px;
  margin:0px;
  font-size: calc(20vw + 3vh);
  color:white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.059);
}

h6{
  font-weight: 300;
  font-size:12px;
  margin-top:10px;
  background-color: white;
  padding:5px 10px;
  margin-bottom:0px;
}

.loading-bar-container {
  width: 100%;
  background-color: #f0f0f0;
  height: 10px;
  top:0px;
  position:absolute;
  margin-bottom: env(safe-area-inset-bottom); /* Add this line */
}

.loading-bar {
  background-color: #ff7700;
  height: 100%;
  transition: width 0.3s ease-in-out;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* App.css */
.bitmap-info {
  position: absolute;
  top: 0;
  left: 0;
  padding: 40px;
  max-width:240px;
  font-family: 'Michroma', sans-serif;
  text-align:left;
  color: #000000; /* Sci-fi green text color */
  font-size: 24px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: black;
}

.terminal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #111;
  width: 500px;
  height: 500px;
  border: 1px solid #888;
  border-radius: 5px;
  color: orange;
  font-family: monospace;
}


.app-header {
  margin-bottom: 20px;
}

footer {
  text-align: center;
  margin-top: 20px;
  position:absolute;
  bottom: 20px;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom); /* Add this line */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
